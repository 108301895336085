@import "styles/style.module.scss";

.hamburgerList {
  padding: 0 16px;
  .list {
    min-height: 52px;
    @include flex(center, flex-start, row);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    width: 100%;
    gap: 8px;
    color: $grey10;
    .arrowIco {
      margin-left: auto;
      &::before {
        font-size: 18px;
        color: $grey10;
      }
    }
    .icon,
    .imgIcon {
      @include flex(center, center, row);
      height: 16px;
      width: 16px;
      flex-shrink: 0;
      font-size: 16px;
      &::before {
        color: $grey10;
      }
    }
  }
}
.subListMain {
  padding: 0 0 0 40px;
  list-style: none;
  .subList {
    color: $grey8;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    > a {
      min-height: 36px;
      @include flex(center, flex-start, row);
    }
  }
}
.arrowUp {
  @include transform(rotate(-180deg));
}
