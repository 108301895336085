@import "styles/style.module.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 102px;
  z-index: 10;
  width: 100%;
  font-family: $fontNew;
  @media only screen and (max-width: 767px) {
    height: 56px;
  }
  &.hideHeader {
    opacity: 0;
    z-index: -1;
  }
  &.mHide {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}
.xl {
  max-width: 1276px;
}
.full {
  max-width: 100%;
}
.backArrow {
  position: absolute;
  top: 90px;
  left: -70px;
  width: 48px;
  height: 48px;
  @include border-radius(50%);
  background-color: $primaryColor;
  cursor: pointer;
  @extend .flexCenter;
  @media only screen and (max-width: 767px) {
    top: 26px;
    left: 21px;
    background: transparent;
    width: 25px;
    height: 24px;
    z-index: 9px;
  }
  span[class^="icon-"] {
    span[class^="path"]:before {
      color: $white !important;
      @media only screen and (max-width: 767px) {
        color: $primaryColor !important;
      }
    }
  }
}
.headerContent {
  background: $white;
  padding: 0;
  height: 100%;
  @include box-shadow(0 2px 10px 0 rgb(0, 0, 0, 0.1));
  position: relative;
  .headerTop {
    @extend .flex;
    margin: auto;
    justify-content: unset;
    max-width: 100%;
    width: 100%;
    padding: 10px 16px;
    @include for-size(phone) {
      height: 100%;
    }
    @include for-size(desktop) {
      max-width: 1275px;
      padding: 6px;
    }
    .headerTopLeft {
      @extend .flex;
      padding-right: 20px;
      width: 200px;
      @include for-size(desktop) {
        width: 324px;
      }
      justify-content: unset;
      [class*="icon-hamburger"] {
        font-size: 24px;
        color: $grey10;
        margin-right: 12px;
        @include for-size(desktop) {
          display: none;
        }
      }
    }
    .search {
      max-width: 628px;
      width: calc(100% - 648px);
      input {
        border-color: $grey6;
        height: 40px;
      }
      .searchInput {
        span {
          height: 16px;
          width: 16px;
        }
      }
    }
    .headerTopRight {
      max-width: 144px;
      @include for-size(desktop) {
        max-width: 324px;
      }
      margin-left: auto;
      &.headerTopRightSuper6 {
        @include flex(center, flex-start, row);
        gap: 24px;
        max-width: unset;
      }
      .IconNavigationList {
        @include flex(center, flex-end, row);
        gap: 8px;
        > li {
          @include flex(center, center, row);
          position: relative;
          cursor: pointer;
          padding: 0 8px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          @include for-size(phone) {
            width: 32px;
            height: 32px;
            padding: 0 4px;
            &:hover,
            &.active {
              background: rgba(255, 255, 255, 0.1);
              @include border-radius(8px);
            }
          }
          .link {
            height: 24px;
            width: 24px;
            overflow: hidden;
            text-decoration: none;
            @include flex("", center, row);
            .userIcon {
              text-transform: uppercase;
              @include flex(center, center, row);
              @include border-radius(50%);
              background: #106c89;
              color: $white;
              font-weight: 500;
              font-size: 16px;
              width: 100%;
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .notifyicons {
            position: absolute;
            right: -6px;
            top: -6px;
            height: 16px;
            width: 16px;
            background: $brandColor5;
            @include flex(center, center, row);
            line-height: 14px;
            font-size: 10px;
            font-weight: 600;
            color: $white;
            @include border-radius(50%);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 1px;
            &.primaryNotify {
              background: $errorColor;
            }
            &.secondaryNotify {
              background: $grey10;
            }
            @include for-size(phone) {
              right: -15%;
              top: -15%;
            }
          }
          img {
            vertical-align: middle;
          }
        }
        @include for-size(phone) {
          gap: 4px;
        }
      }
    }
  }
  .headerBottom {
    border-top: 1px solid $grey3;
    max-width: 100vw;
    .navigation {
      max-width: 80%;
      margin: auto;
      li {
        list-style-type: none;
      }
    }

    @media only screen and (max-width: 767.9px) {
      border-top: unset;
      padding: 0px 16px 16px;
      background: #fff;
      border-bottom: 1px solid $grey3;
    }
    &.hideSearch {
      border: unset;
      padding: 0;
    }
  }
}
.searchRoot {
  position: fixed;
  left: 0;
  right: 0;
  top: 61px;
  bottom: 0;
  background: white;
  z-index: 8;
  animation: searchOpen 0.3s;
  @include for-size(phone) {
    top: 135px;
  }
  @keyframes searchOpen {
    0% {
      @include transform(scale(0));
    }
    100% {
      @include transform(scale(1));
    }
  }
}

.logoWrapper {
  @extend .flex;
  justify-content: unset;
  padding-right: 20px;
  h1 {
    margin-right: 20px;
    cursor: pointer;
  }
}
.logoOnly {
  display: block;
  height: 48px;
  @media only screen and (min-width: 768px) and (max-width: 900px) {
    padding: 13px 0px;
  }
  @media only screen and (max-width: 767px) {
    padding: 13px 0 13px 10px;
    height: 72px;
  }
  img {
    width: 70px;
    @media only screen and (max-width: 767px) {
      width: 60px;
    }
  }
}
.logo {
  width: 80px;
  height: 48px;
  display: block;
  @media only screen and (max-width: 767px) {
    width: 60px;
    max-height: 60px;
  }
  img {
    width: 100%;
  }
}

.locationPicker {
  width: calc(100% - 90px);
  @media only screen and (max-width: 767.9px) {
    width: 100%;
  }
}

.menuContainer {
  height: 100%;
  @extend .flex;
  justify-content: unset;
}

.cartContainer {
  position: relative;
  height: 100%;
  @extend .flexCenter;
  cursor: pointer;
  padding: 10px;
  > span {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
  .badge {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 14px;
    height: 14px;
    background: #ff748e;
    color: #fff;
    font-size: 8px;
    border-radius: 14px;
    @extend .flexCenter;
    opacity: 1;
  }
  @media only screen and (max-width: 767px) {
    width: 45px;
  }
}

.userOptions {
  padding: 10px;
  width: 68px;
  height: 68px;
}

.userCircle {
  display: flex;
  width: 48px;
  height: 48px;
  background: $primaryColor;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}
.userActive {
  background: green;
}
.specialOffer {
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
    height: 100%;
  }
  a {
    padding: 10px;
    height: 80px;
    width: 45px;
    @extend .flexCenter;
    span {
      font-size: 24px;
      width: 24px;
      height: 24px;
      padding: 0;
    }
  }
}
.miniAppUser {
  .logoWrapper {
    padding-right: 0;
  }
  .badge {
    right: 6px;
  }
  .userOptions {
    transform: scale(0.8);
    padding-left: 0;
  }
}

.popOver {
  > div:first-child {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.loginForm {
  width: 340px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.3);
  background: $white;
  min-height: 240px;
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
}
.topPopover {
  overflow: initial;
  background: none;
  box-shadow: none;
}

.userAccount {
  width: 48px;
  height: 48px;
  @include border-radius(50%);
  cursor: pointer;
  background: $primaryColor;
  @extend .flexCenter;
}

.userAccountDetails {
  background: $white;
  position: absolute;
  top: 85px;
  right: 0px;
  left: auto;
  width: 0;
  border-radius: 10px;
  transition: 0.1s ease;
  overflow: hidden;
  z-index: 3;
  @include box-shadow(0 2px 10px 0 rgb(0, 0, 0, 0.1));
  @media only screen and (max-width: 600px) {
    top: 70px;
  }
}
.userAccountList {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  li {
    border-bottom: 1px solid rgba(2, 71, 91, 0.3);
    a {
      padding: 8px 20px;
      font-family: $font;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        align-items: center;
        img {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    li {
      a {
        padding: 6px 10px !important ;
        font-size: 12px;
      }
    }
  }
}
.downloadAppBtn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 12px;
  font-family: $fontBold;
  padding: 8px 20px;
  display: block;
  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
}
.userListActive {
  width: 300px;
  @media only screen and (max-width: 600px) {
    width: 240px;
  }
}
.userDetails {
  padding: 10px 20px;
  text-align: left;
  cursor: auto;
  h2 {
    font-size: 23px;
    font-family: $fontBold;
    padding: 0 0 10px;
    margin: 0;
    border-bottom: 1px solid rgba(2, 71, 91, 0.3);
  }
  p {
    font-size: 14px;
    font-family: $fontMedium;
    padding: 8px 0;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 600px) {
    padding: 5px 10px;
    h2 {
      font-size: 16px;
      padding: 0 0 8px;
    }
    p {
      font-size: 12px;
      padding: 8px 0;
    }
  }
}

.mWebSearchInput {
  span {
    height: 20px !important;
    width: 20px !important;
  }
  input::placeholder {
    color: $grey8 !important;
  }
}

.backButton {
  margin-right: 12px;
}

.loginCta {
  &.expanded {
    min-width: 120px;
  }
  .webLogin {
    @include flex(center, flex-start, row);
    @include border-radius(8px);
    border: 1px solid $pharma6;
    cursor: pointer;
    height: 40px;
    padding: 0 12px 0 16px;
    @include for-size(phone) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .loginCTATxt {
    font-weight: 600;
    margin-right: 12px;
    color: $pharma6;
    @include for-size(desktop) {
      font-size: 14px;
    }
    @include for-size(phone) {
      font-size: 12px;
    }
  }
}

.icon {
  height: 48px;
  width: 48px;
  @include flex(center, center, row);
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  @include for-size(phone) {
    height: 24px;
    width: 24px;
  }
  &.userIcon {
    height: 24px;
    width: 24px;
    font-size: 20px;
    &::before {
      color: $pharma6;
    }
  }
  &::before {
    color: $grey10;
    @include for-size(phone) {
      color: #fafbf9;
    }
  }
}
