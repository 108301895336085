@import "styles/style.module.scss";

.sideNavMenuModal {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  height: 100dvh;
  overflow: auto;
}
.ssrView {
  opacity: 0;
}
.overlay {
  position: fixed;
  inset: 0;
  background: rgba($grey10, 0.6);
  z-index: 1;
}
.sideNavMenuWrap {
  background: white;
  max-width: 244px;
  height: 100dvh;
  z-index: 2;
  position: relative;
}
.listWrap {
  z-index: 1;
  position: relative;
  height: calc(100dvh - 56px);
  overflow: hidden auto;
  padding-bottom: 84px;
}
.greeting {
  position: sticky;
  padding: 24px 20px 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: $grey10;
}
